import { ReactNode, useContext, useState } from "react"
import { useDonationAmount } from "../../hooks/useDonationAmount"
import { format } from "date-fns"
import { ServiceContext } from "../../pages/[id]/[slug]"
import { CampaignLanguageEnum } from "../../types"

const GiftSummaryRow = ({
  label,
  value
}: {
  label: ReactNode
  value: string
}) => (
  <div className="gift-summary-row">
    <p>{label}</p>
    <p>${value}</p>
  </div>
)

const useTranslations = (locale: string) => ({
  gift_summary_title: {
    en: "SUMMARY",
    es: "RESUMEN"
  }[locale],
  gift_summary_today_label: {
    en: "Your Gift Today:",
    es: "Tu regalo de hoy:"
  }[locale],
  gift_summary_recurring_label: {
    en: "Your Recurring Gift Beginning",
    es: "Tu regalo recurrente comenzando"
  }[locale],
  help_cover_fees_label: {
    en: "Help cover processing fees?",
    es: "¿Ayudar a cubrir los costos de procesamiento?"
  }[locale],
  help_cover_fees_tooltip_button_label: {
    en: "learn more about covered processing fees",
    es: "aprender más sobre los costos de procesamiento cubiertos"
  }[locale],
  help_cover_fees_description_start: {
    en: "This will increase your gift today by",
    es: "Esto aumentará tu regalo de hoy en"
  }[locale],
  help_cover_fees_description_end: {
    en: "to help cover credit card and bank processing fees.",
    es: "para ayudar a cubrir los costos de procesamiento de tarjetas de crédito y bancarias."
  }[locale]
})

export const GiftSummary = ({
  language,
  enableDonorCoveredFees
}: {
  language: CampaignLanguageEnum
  enableDonorCoveredFees: boolean
}) => {
  const { donationAmountService } = useContext(ServiceContext)

  const {
    isDonating,
    donationTodayAmount,
    donationFeesCovered,
    feesCalculator,
    feesAndAmountCalculator,
    recurStartDate,
    recurringGiftAmount
  } = useDonationAmount()

  const [donorCoveredFeesTooltip, setDonorCoveredFeesTooltip] = useState(false)

  const locale = language.split("-")[0]

  const startDateDisplay = recurStartDate
    ? format(recurStartDate, locale === "en" ? "MMM do" : "d 'de' MMM")
    : ""

  const handleCoverFeesChange = () => {
    donationAmountService.send({
      type: "TOGGLE_FEES_COVERED"
    })
  }

  const displayAmount = (amount: string) => `$${amount}`

  const t = useTranslations(locale)

  return isDonating ? (
    <div className="gift-summary">
      {enableDonorCoveredFees && (
        <div className="covered-fees-container">
          <input
            type="checkbox"
            id="cover-fees"
            name="cover-fees"
            checked={donationFeesCovered}
            className="sr-only"
            onChange={handleCoverFeesChange}
          />
          <label htmlFor="cover-fees" className="covered-fees-label">
            {t.help_cover_fees_label}
            <button
              type="button"
              id="learn-more-btn"
              aria-label={t.help_cover_fees_tooltip_button_label}
              onClick={() => setDonorCoveredFeesTooltip((value) => !value)}
              className="covered-fees-tooltip-button"
            >
              <svg
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                width="16"
                height="16"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                ></path>
              </svg>
            </button>
          </label>
          {donorCoveredFeesTooltip && (
            <div className="covered-fees-tooltip">
              {Number(donationTodayAmount) && !Number(recurringGiftAmount) ? (
                <p>
                  {t.help_cover_fees_description_start}
                  &nbsp;
                  <span className="underline">
                    {displayAmount(feesCalculator(donationTodayAmount))}
                  </span>
                  &nbsp;
                  {t.help_cover_fees_description_end}
                </p>
              ) : null}
              {Number(recurringGiftAmount) ? (
                <p>
                  {t.help_cover_fees_description_start}
                  &nbsp;
                  <span className="underline">
                    {displayAmount(feesCalculator(recurringGiftAmount))}
                  </span>
                  &nbsp;
                  {t.help_cover_fees_description_end}
                </p>
              ) : null}
            </div>
          )}
        </div>
      )}
      <p id="gift-summary-title">
        <span>{t.gift_summary_title}</span>
      </p>
      <div id="gift-summary-body">
        {Number(donationTodayAmount) && (
          <div>
            <GiftSummaryRow
              label={t.gift_summary_today_label}
              value={feesAndAmountCalculator(donationTodayAmount)}
            />
          </div>
        )}
        {Number(recurringGiftAmount) ? (
          <div>
            <GiftSummaryRow
              label={`${t.gift_summary_recurring_label} ${startDateDisplay}:`}
              value={feesAndAmountCalculator(recurringGiftAmount)}
            />
          </div>
        ) : null}
      </div>
    </div>
  ) : null
}
